<template>
    <div 
        class="tab-pane fade"
        id="kt_forms_Overview_1_tab_content_3"
    >
      <div class="container ps-2 pe-2">
        <div class="row">
            <template 
                v-for="stats in Statistics" 
                :key="stats.title"
            >
                <ChartModel 
                    v-if="Object.keys(graphs).length > 0 && graphs[stats.key] && stats.isChart"
                    :chartOptions="graphs[stats.key].options"
                    :series="graphs[stats.key].series"
                    :title="stats.title"
                    :backgroundColor="stats.backgroundColor"
                    :col="stats.col"
                    :commonClass="stats.class"
                />
                <TableModel 
                    v-if="Object.keys(tableData).length > 0 && !stats.isChart"
                    :data="tableData[stats.key]"
                    :headers="stats.headers"
                    :title="stats.title"
                    :background-color="stats.backgroundColor"
                    :common-class="stats.class"
                    :col="stats.col"
                    :show-count="stats.showCount"
                    :show-div="stats.showDiv"
                    :counts="tableData[stats.key].length"
                    :start="dFilters.start_date"
                    :end="dFilters.end_date"
                />
            </template>
            
            
        </div>
      </div>
    </div>
</template>

<script setup>
import { computed, defineProps } from "vue";
import TableModel from "@/views/NEW/Dashboard/Models/TableModel.vue";
import ChartModel from "@/views/NEW/Dashboard/Models/ChartModel.vue";
import { dFilters } from "@/store/composable/Dashboard";
import { Statistics } from "@/core/utils/DashboardTables";
import { useStore } from "vuex";

const props = defineProps({
  statistics: {
    type: Function,
    required: true,
  },
});

const store = useStore();

const graphs = computed(() => {
    if(props.statistics)
        return store.getters.getGraphStatisticsDashboard(props.statistics.leadSourceGraph)

    return {}
})

const tableData = computed(() => {
    if(props.statistics) 
        return store.getters.getTableStatisticsDashboard(props.statistics)

    return {}
})
</script>
