<template>
    <div class="ps-0">
        <div class="container p-0">
            <div class="d-flex">
                <template v-for="day in days" :key="day.label">
                    <a v-if="day.hasCondition && day.condition"
                        @click="onChangeDateTab(day.click)"
                        :class="{
                          'btn btn-outline btn-outline-dashboard-filter active':
                            dFilters.type == day.click,
                          'btn btn-outline btn-dashboard-filter border': dFilters.type != day.click
                        }"
                        class="ps-3 pe-3 pt-3 mr-10"
                        style="border-radius: 0"
                        >{{ day.label }}
                      </a>
                    <a v-if="!day.hasCondition"
                        @click="onChangeDateTab(day.click)"
                        :class="{
                          'btn btn-outline btn-outline-dashboard-filter active':
                            dFilters.type == day.click,
                          'btn btn-outline btn-dashboard-filter border': dFilters.type != day.click
                        }"
                        class="ps-3 pe-3 pt-3 mr-10"
                        style="border-radius: 0"
                        >{{ day.label }}
                      </a>
                </template>
                <div 
                v-if="[2, 9, 13].includes(role_id) && route.name !== 'admin-dashboard'"
                class="text-center datepicker-dropdown">
                    <vc-date-picker-range 
                          v-model="daterange"
                          @change="onChangeDatePicker"
                    />
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import { ref, watch, defineEmits } from "vue";
import { useUser, useRoleBasedConditions } from '@/store/composable/User'
import { dFilters } from '@/store/composable/Dashboard'
import  moment from "moment";
import { useRoute } from 'vue-router'

const route = useRoute()
const { role_id } = useUser()
const { adminDashboard } = useRoleBasedConditions()

const daterange = ref([])
const emit = defineEmits(['click'])

const days = ref([
    {
        label: 'Today',
        click: 'today',
        hasCondition: true,
        condition: adminDashboard || route.name === 'admin-dashboard',
    },
    {
        label: 'Yesterday',
        click: 'yesterday',
        hasCondition: true,
        condition: adminDashboard || route.name === 'admin-dashboard',
    },
    {
        label: 'This Month',
        click: 'month',
        hasCondition: false,
    },
    {
        label: 'Last Month',
        click: 'lastmonth',
        hasCondition: false,
    },
    {
        label: 'Previous Month',
        click: 'PreviousMonth',
        hasCondition: false,
    },
])

watch(() => dFilters.value, newValue => {
    daterange.value = [newValue.start_date, newValue.end_date]

}, {deep: true})

function onChangeDateTab(value) {
    dFilters.value.type = value
    if(dFilters.value.type == 'today') {
        dFilters.value.start_date = moment().format('YYYY-MM-DD')
        dFilters.value.end_date = moment().format('YYYY-MM-DD')
    } else if(dFilters.value.type == 'yesterday') {
        dFilters.value.start_date = moment().subtract(1, 'days').format('YYYY-MM-DD')
        dFilters.value.end_date = moment().subtract(1, 'days').format('YYYY-MM-DD')
    } else if(dFilters.value.type == 'month') {
        dFilters.value.start_date = moment().startOf('month').format('YYYY-MM-DD')
        dFilters.value.end_date = moment().endOf('month').format('YYYY-MM-DD')
    } else if(dFilters.value.type == 'lastmonth') {
        dFilters.value.start_date = moment().subtract(1, 'months').startOf('month').format("YYYY-MM-DD")
        dFilters.value.end_date = moment().subtract(1, 'months').endOf('month').format("YYYY-MM-DD")
    } else if(dFilters.value.type == 'PreviousMonth') {
        dFilters.value.start_date = moment().subtract(2, 'months').startOf('month').format("YYYY-MM-DD")
        dFilters.value.end_date = moment().subtract(2, 'months').endOf('month').format("YYYY-MM-DD")
    }

    emit('click')
}

function onChangeDatePicker(value) {
    dFilters.value.type = ''
    if(!value) {
        value = []
        if(adminDashboard) {
            onChangeDateTab('today')
        } else {
            onChangeDateTab('month')
        }
    }
        

    if(value.length > 0) {
        dFilters.value.start_date = value[0]
        dFilters.value.end_date = value[1]
    }
}
</script>
