<template>
    <div 
        class="tab-pane fade"
        id="kt_forms_Overview_1_tab_content_4"
    >
      <div class="container ps-2 pe-2">
        <div class="row">
            <template 
                v-for="info in OtherInfos" 
                :key="info.title"
            >
                <TableModel 
                    v-if="getOtherInfo[info.key]"
                    :data="getOtherInfo[info.key].data"
                    :other-data="getOtherInfo[info.key]"
                    :headers="info.headers"
                    :title="info.title"
                    :background-color="info.backgroundColor"
                    :background-font-color="info.backgroundFontColor"
                    :common-class="info.class"
                    :col="info.col"
                    :show-div="info.showDiv"
                    :is-pagination="info.isPagination"
                    :link-type="info.linkType"
                    :show-top-text="info.showTopText"
                    :top-values="getOtherInfo[info.key].topValues"
                    :start="dFilters.start_date"
                    :end="dFilters.end_date"
                    @pagination="handlePagination"
                />
            </template>
            
            
        </div>
      </div>
    </div>
</template>

<script setup>
import { computed, defineProps, defineEmits } from "vue";
import TableModel from "@/views/NEW/Dashboard/Models/TableModel.vue";
import { dFilters } from "@/store/composable/Dashboard";
import { OtherInfos } from '@/core/utils/DashboardTables'
import { useStore } from "vuex";

const props = defineProps({
  otherInfo: {
    type: Function,
    required: true,
  },
});

const store = useStore();

const emit = defineEmits([ 'pagination' ])

const getOtherInfo = computed(() => {
  return store.getters.getOtherInfoDashboard(props.otherInfo)
})

function handlePagination(page, linkType, start, end) {
  emit('pagination', page, linkType, start, end)
}


</script>
