<template>
        <div class="col-xxl-12 dashboard-filter-row">
            <div class="card h-xl-100">
                <div class="row">
                    <div class="col-md-12">
                        <ul class="leads-policy nav nav-tabs nav-line-tabs fs-5">
                            <li 
                                v-for="(tab, i) in tabs"
                                :key="i"
                                class="nav-item"
                            >
                                <a 
                                    :class="tab.class"
                                    data-bs-toggle="pill"
                                    :id="tab.id"
                                    :href="tab.href"
                                    @click="updateTab(i)"
                                >
                                    {{ tab.label }}
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xxl-12 dashboard-filter-row admin-dashboard-row">
            <div class="tab-content">
                <Overviews 
                    :over-views="overViews"
                />
                <SalesPerson 
                    :sales-person="salesPersonInfo"
                />
                <Statistics 
                    :statistics="statistics"
                />
                <OtherInfo 
                  :other-info="otherInfo"
                  @pagination="handlePagination"
                />
            </div>
        </div>
</template>
<script setup>
import { ref, defineProps, defineEmits } from "vue";
import { dFilters } from "@/store/composable/Dashboard";
import Overviews from '@/views/NEW/Dashboard/Views/Tabs/Overviews.vue'
import SalesPerson from '@/views/NEW/Dashboard/Views/Tabs/SalesPerson.vue'
import Statistics from '@/views/NEW/Dashboard/Views/Tabs/Statistics.vue'
import OtherInfo from '@/views/NEW/Dashboard/Views/Tabs/OtherInfo.vue'

defineProps({
  overViews: {
    type: Function,
    required: true,
  },
  salesPersonInfo: {
    type: Object,
    required: true
  },
  statistics: {
    type: Object,
    required: true
  },
  otherInfo: {
    type: Object,
    required: true
  }
});

const tabs = ref([
  {
    label: "Overview",
    class: "nav-link cursor-pointer active",
    id: "kt_forms_Overview_1_tab_1",
    href: "#kt_forms_Overview_1_tab_content_1",
  },
  {
    label: "Sales Person Info",
    class: "nav-link cursor-pointer",
    id: "kt_forms_Overview_1_tab_2",
    href: "#kt_forms_Overview_1_tab_content_2",
  },
  {
    label: "Statistics",
    class: "nav-link cursor-pointer",
    id: "kt_forms_Overview_1_tab_3",
    href: "#kt_forms_Overview_1_tab_content_3",
  },
  {
    label: "Other Info",
    class: "nav-link cursor-pointer",
    id: "kt_forms_Overview_1_tab_4",
    href: "#kt_forms_Overview_1_tab_content_4",
  },
]);

function updateTab(i) {
    dFilters.value.tab = i
}

const emit = defineEmits([ 'pagination' ])

function handlePagination(page, linkType, start, end) {
  emit('pagination', page, linkType, start, end)
}


</script>
