<template>
    <div class="d-flex align-items-center">
        <template v-if="[2, 9, 13, 4].includes(role_id) && route.name !== 'admin-dashboard'">
            <span
                v-for="task in tasks"
                :key="task.label_1"
                class="ps-3 pe-3 pt-3 pb-3 mr-10"
                style="border-radius: 0"
                ><h4 class="top-title">{{ role_id == 4 ? task.label_2 : task.label_1 }} <span class="dashboard-text-orange">{{ task.value.replace('OVERDUE_TASK', overDueTasks).replace('TASK_TODAY', taskToday) }}</span></h4>   
            </span>
        </template>
        <template v-if="[1, 14].includes(role_id) || route.name === 'admin-dashboard'" >
            <div class="text-center datepicker-dropdown">
                <vc-date-picker-range 
                    v-model="daterange"
                    @change="onChangeDatePicker"
                />
            </div>
            <div class="justify-content-end">
                <el-select v-model="year" @change="changeYear">
                    <el-option v-for="year in yearsList" :key="year" :value="year" :label="year" />
                </el-select>
            </div>
        </template>
    </div>
</template>

<script setup>
import { ref, defineProps, computed, watch, defineExpose } from 'vue'
import { useUser, useRoleBasedConditions } from '@/store/composable/User'
import { dFilters } from '@/store/composable/Dashboard'
import  moment from "moment";
import { useRoute } from 'vue-router'

const props = defineProps({
    overDueTasks: {
        type: Number,
        required: false,
        default: 0
    },
    taskToday: {
        type: Number,
        required: false,
        default: 0
    },
})

const route = useRoute()
const { role_id } = useUser()
const { adminDashboard } = useRoleBasedConditions()

const daterange = ref([])
const year = ref('')

const currentyear = new Date().getFullYear();
const yearsList = computed(() => {
    return Array.from(
    { length: currentyear - 2017 },
    (value, index) => currentyear - index
    );
});

watch(() => dFilters.value, newValue => {
    daterange.value = [newValue.start_date, newValue.end_date]

}, {deep: true})

const tasks = ref([
    {
        label_1: 'Over Due Tasks: ',
        label_2: 'Over Due: ',
        class: 'ps-3 pe-3 pt-3 pb-3 mr-10',
        value: 'OVERDUE_TASK'
    },
    {
        label_1: 'Tasks Today: ',
        label_2: 'Assigned Status Today: ',
        class: 'ps-3 pe-3 pt-3 pb-3',
        value: "TASK_TODAY"
    },
])

function changeYear() {
    onChangeDatePicker([`${year.value}-01-01`, `${year.value}-12-31`], true)
}

function onChangeDatePicker(value, isClick = false) {
    dFilters.value.type = ''
    if(!isClick) 
        year.value = ''

    if(!value) {
        value = []
        if(adminDashboard || route.name === 'admin-dashboard') {
            onChangeDateTab('today')
        } else {
            onChangeDateTab('month')
        }
    }

    if(value.length > 0) {
        dFilters.value.start_date = value[0]
        dFilters.value.end_date = value[1]
    }
}


function onChangeDateTab(value) {
    dFilters.value.type = value
    if(dFilters.value.type == 'today') {
        dFilters.value.start_date = moment().format('YYYY-MM-DD')
        dFilters.value.end_date = moment().format('YYYY-MM-DD')
    } else if(dFilters.value.type == 'yesterday') {
        dFilters.value.start_date = moment().subtract(1, 'days').format('YYYY-MM-DD')
        dFilters.value.end_date = moment().subtract(1, 'days').format('YYYY-MM-DD')
    } else if(dFilters.value.type == 'month') {
        dFilters.value.start_date = moment().startOf('month').format('YYYY-MM-DD')
        dFilters.value.end_date = moment().endOf('month').format('YYYY-MM-DD')
    } else if(dFilters.value.type == 'lastmonth') {
        dFilters.value.start_date = moment().subtract(1, 'months').startOf('month').format("YYYY-MM-DD")
        dFilters.value.end_date = moment().subtract(1, 'months').endOf('month').format("YYYY-MM-DD")
    } else if(dFilters.value.type == 'PreviousMonth') {
        dFilters.value.start_date = moment().subtract(2, 'months').startOf('month').format("YYYY-MM-DD")
        dFilters.value.end_date = moment().subtract(2, 'months').endOf('month').format("YYYY-MM-DD")
    }
}

function reset() {
    year.value = ''
}

defineExpose({ reset })

</script>
