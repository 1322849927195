<template>
    <div :class="`col-sm-${data.col} ${data.top_class}`">
        <div class="card" v-if="type == 'sasmtl'">
            <div class="card-body me-0">
            <i :class="data.icon"></i>
            <h3 v-html="data.label"></h3>
            <h2 
                :class="data.class_1" 
                :style="`font-size: 
                ${getTextWidth(data.value_1) > 115 ? '16px' 
                : getTextWidth(data.value_1) > 105 ? '18px' 
                : getTextWidth(data.value_1) > 95 ? '20px' 
                : '25px'}`">
                {{ data.value_1 }}
            </h2>
            </div>
        </div>

        <div class="card" v-if="type == 'underwriter'">
            <div class="card-body">
                <i :class="data.icon"></i>
                <h2 :class="data.class_1">{{ data.value_1 }}</h2>
                <h3 v-html="data.label" />
            </div>
        </div> 
    </div>
</template>

<script setup>
import { defineProps } from 'vue'

const props = defineProps({
    data: {
        type: Function,
        required: true,
    },
    type: {
        type: String,
        required: false,
        default: 'sasmtl'
    }
})

function getTextWidth(text, font) {
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");

  context.font = font || getComputedStyle(document.body).font;

  return context.measureText(text).width;
}
</script>
