<template>
    <div 
        class="tab-pane fade active show"
        id="kt_forms_Overview_1_tab_content_1"
    >
        <div
            v-for="(row, i) in overViews"
            :key="i"
            class="card mb-5 rounded-2 full-width"
            >
            <div :class="extraClass(i)">
                <BoxModel 
                    v-for="(data, i) in row"
                    :key="i"
                    :data="data"
                    :start="dFilters.start_date"
                    :end="dFilters.end_date"
                />
            </div>
        </div>
    </div>
</template>

<script setup>
import { defineProps } from "vue";
import BoxModel from "@/views/NEW/Dashboard/Models/BoxModel.vue";
import { dFilters } from "@/store/composable/Dashboard";

defineProps({
  overViews: {
    type: Function,
    required: true,
  },
});

function extraClass(row) {
  if (row == "row_8") return "row g-5 w-100 untouched-row bg-light-white";
  else if (row == "row_9" || row == "row_10")
    return "row g-5 refund-row bg-light-white";
  else if (row == "row_11") return "row g-5 miscellaneous-row bg-light-white";

  return "row g-5 bg-light-white";
}
</script>
